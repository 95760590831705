import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import { Answer } from "../../store/answer";
import { QuestionType } from "../../common/questionType";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import AddQuestionManagementEntryModal from "./AddQuestionManagementEntryModal";
import { QuestionManagementState } from "../../store/questionManagement";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { questionManagementActionCreators } from "../../actions/questionManagementActions";
import { connect, ConnectedProps } from "react-redux";

interface AddQuestionManagementEntryButtonsProps extends ConnectedProps < typeof connector > {
    answer: Answer;
}

const AddQuestionManagementEntryButtons: FC<AddQuestionManagementEntryButtonsProps> = (props) => {
    const [showAddQuestionManagementEntryModal, setShowAddQuestionManagementEntryModal] = useState(false);
    const [questionTypeToAdd, setQuestionTypeToAdd] = useState<QuestionType | null>(null);
    
    const { answer } = props;
    const { questionManagementState } = props;
    const { readQuestionManagementData } = props;

    const isAdmin = useIsAdmin();
    const isLoading = questionManagementState.isLoading;

    const handleAddQuestionManagementEntryModalClose = () => {
        setShowAddQuestionManagementEntryModal(false);
    }

    useEffect(() => {
        if ((questionManagementState.data === undefined || questionManagementState.error) && !questionManagementState.isLoading) {
            readQuestionManagementData();
        }
    }, []);

    const handleAddRaqButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setQuestionTypeToAdd(QuestionType.Raq);
        setShowAddQuestionManagementEntryModal(true);
    }

    const handleAddFaqButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setQuestionTypeToAdd(QuestionType.Faq);
        setShowAddQuestionManagementEntryModal(true);
    }
    return (
        <>
            <AddQuestionManagementEntryModal isVisible={showAddQuestionManagementEntryModal} questionType={questionTypeToAdd} questionText={answer.question} onClose={handleAddQuestionManagementEntryModalClose} />
            
            {isAdmin && (
                <div className="button-wrapper">
                    <button className="save" disabled={isLoading} onClick={handleAddRaqButtonClick}><span id="save-button-text"><PlusCircleIcon className="plus-icon" /> Add<span className="this-question-text"> this question</span> to RAQs</span></button>
                    <button className="save" disabled={isLoading} onClick={handleAddFaqButtonClick}><span id="save-button-text">Add<span className="this-question-text"> this question</span> to FAQs  <PlusCircleIcon className="plus-icon" /></span></button>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const questionManagementState = state.questionManagement;

    return { questionManagementState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    readQuestionManagementData: () => {
        dispatch(questionManagementActionCreators.readQuestionManagementData());
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AddQuestionManagementEntryButtons);