import { FC, useState } from "react";
import { AnswerPrompt } from "../../store/answer";
import * as ArrayHelper from "../../common/arrayHelper";
import { ChevronDown as ChevronDownIcon } from "react-feather";
import { useNonChildElementClick } from "../../hooks/useNonChildElementClick";
import { updateRecord } from "../../services/reportingService";

type PromptListProps = {
    prompts: AnswerPrompt[];
    onSelect: (selectionIndex: number) => void;
    value: number | undefined;
    reportingId?: number;
};

export const AnswerPromptDropdown: FC<PromptListProps> = (props) => {
    const [areChoicesVisible, setAreChoicesVisible] = useState(false);

    const getSelectText = () => {
        let selectedPromptText = "Please select";
        
        if (ArrayHelper.isValidIndex(props.value, props.prompts)) {
            selectedPromptText = props.prompts[props.value as number].displayText;
        }
        
        return selectedPromptText;
    }
    
    const handleLabelClick = () => {
        setAreChoicesVisible(!areChoicesVisible);
    }
    
    const handleSelect = (selectionIndex: number) => {
        setAreChoicesVisible(false);
        props.onSelect(selectionIndex);
        if (props.reportingId != null) {
            updateRecord(props.reportingId.toString());
        }       
    }
    
    const handleNonChildElementClick = () => {
        setAreChoicesVisible(false);
    }

    const dropdownElementRef = useNonChildElementClick<HTMLDivElement>(handleNonChildElementClick);
    
    return (
        <div className="decision-list">
            <div className="decision-choices" ref={dropdownElementRef}>
                
                <p>
                    <a className="decision-select" onClick={handleLabelClick}>
                        <span>{getSelectText()}</span>&nbsp;&nbsp;<ChevronDownIcon className="chevron-down" />
                    </a>
                </p> 
                
                <div className={areChoicesVisible ? "choices" : "hidden"}>
                    <div className="decision-list-arrow">
                        <span className="decision-arrow-outline" />
                        <span className="decision-arrow" />
                    </div>
                    
                    <ul>
                        {props.prompts.map((prompt, index) => (
                            <li key={index}>
                                <a onClick={() => handleSelect(index)}>{prompt.displayText}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                
            </div>
        </div>
    );
}
