import { ChangeEventHandler, createRef, DragEventHandler, FC, MouseEventHandler, useState } from "react";
import Modal from "../Modal";
import { supportedMimeTypes } from "../../constants/bannerConstants";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { Banner } from "../../store/banner";
import { bannerActionCreators } from "../../actions/bannerActions";
import Suspense from "../suspense/Suspense";
import * as FileHelper from "../../common/fileHelper";

interface UpdateBannerModalProps extends ConnectedProps<typeof connector> {
    isVisible: boolean;
    onClose: () => void;
}

const UpdateBannerModal: FC<UpdateBannerModalProps> = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);
    
    const { isVisible, onClose } = props;
    const { bannerState } = props;
    const { upsertActiveBanner } = props;
    
    // File inputs are always uncontrollable, so we need to use refs to clear the input after a file has been selected
    // See: https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
    const fileInputRef = createRef<HTMLInputElement>();
    
    const fileHasSupportedMimeType = (file: File) => {
        return supportedMimeTypes.includes(file.type);
    }
    
    const getSupportedFileTypesAccept = () => {
        return supportedMimeTypes.join(",");
    }
    
    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }
    
    const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
        // All drag events must prevent default to prevent the default browser behavior of opening the file
        // when it is dropped
        event.stopPropagation();
        event.preventDefault();
    }

    const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
        // All drag events must prevent default to prevent the default browser behavior of opening the file
        // when it is dropped
        event.stopPropagation();
        event.preventDefault();
        
        setIsDragging(true);
    }
    
    const handleDragLeave: DragEventHandler<HTMLDivElement> = (event) => {
        // All drag events must prevent default to prevent the default browser behavior of opening the file
        // when it is dropped
        event.stopPropagation();
        event.preventDefault();
        
        // If the user drags over a child element, the dragleave event will be fired
        // Ignore it
        if (event.currentTarget.contains(event.relatedTarget as Node | null)) {
            return;
        }
        
        setIsDragging(false);
    }
    
    const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
        // All drag events must prevent default to prevent the default browser behavior of opening the file
        // when it is dropped
        event.stopPropagation();
        event.preventDefault();
        
        event.dataTransfer.dropEffect = "move";
        
        setIsDragging(false);

        const file = event.dataTransfer.files.item(0);
        if (file !== null) {
            if (!fileHasSupportedMimeType(file)) {
                setValidationError("The file type is not supported");
            }
            else {
                setFileToUpload(file);
                setValidationError(null);
            }
        }
        clearFileInput();
    }
    
    const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.currentTarget.files?.item(0) ?? null;
        if (file !== null) {
            if (!fileHasSupportedMimeType(file)) {
                setValidationError("The file type is not supported");
            }
            else {
                setFileToUpload(file);
                setValidationError(null);
            }
        }
        
        // Clear file input after each change so that the next change can be detected if it is the same file
        // This is useful when the user selects a file, then removes it, then tries to select it again
        clearFileInput();
    }
    
    const handleRemoveFileButtonClick: MouseEventHandler<HTMLParagraphElement> = (event) => {
        setFileToUpload(null);
    }
    
    const handleUploadButtonClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setIsSubmitted(true);
        
        if (fileToUpload !== null && validationError === null) {
            const fileContent = await FileHelper.toBase64(fileToUpload);
            
            upsertActiveBanner({
                fileName: fileToUpload.name,
                content: fileContent,
                type: fileToUpload.type
            });
        }
    }

    const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setFileToUpload(null);
        setIsSubmitted(false);
        setValidationError(null);
        onClose();
    }
    
    const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setFileToUpload(null);
        setIsSubmitted(false);
        setValidationError(null);
        onClose();
    }
    
    const getSubmittedMessage = () => {
        if (fileToUpload === null) {
            return "Please select a file to upload.";
        }
        else if (bannerState.error) {
            return bannerState.error.message;
        }
        
        return "Banner updated successfully.";
    }
    
    return (
        <Modal isVisible={isVisible}>
            <Suspense isLoading={bannerState.isLoading}>
                {!isSubmitted ? (
                    <>
                        <h2>Update Ask Alice Banner</h2>
                        <p>PNG, JPEG, JPG, SVG, or MP4 files 1800 x 305</p>

                        <form id="upload">
                            <div className={!isDragging ? "file-upload" : "file-upload-dragging"} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                                <i className="fa fa-cloud-upload" />
                                <div className="upload-text">
                                    <input ref={fileInputRef} id="file-browse" type="file" className="hidden" onChange={handleFileInputChange} accept={getSupportedFileTypesAccept()} />
                                    <p>Drag and drop or &nbsp;</p><label htmlFor="file-browse">browse</label><p>&nbsp; to choose a file</p>
                                </div>
                            </div>

                            {validationError !== null && (
                                <p className="validation-error">{validationError}</p>
                            )}

                            {fileToUpload !== null && (
                                <div className="file-loading">
                                    <p>{fileToUpload.name}</p>
                                    <p className="cancel-upload" onClick={handleRemoveFileButtonClick}>Cancel</p>
                                </div>
                            )}
                        </form>

                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCancelButtonClick}>
                                <i className="fa fa-times" />  CANCEL
                            </button>

                            <button className="update" onClick={handleUploadButtonClick}>
                                <i className="fa fa-cloud-upload" /> UPLOAD
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2>{getSubmittedMessage()}</h2>
                        
                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCloseButtonClick}>
                                <i className="fa fa-times" />  Close
                            </button>
                        </div>
                    </>
                )}
            </Suspense>
        </Modal>
    );
}

const mapStateToProps = (applicationState: ApplicationState) => {
    const bannerState = applicationState.banner;

    return { bannerState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    upsertActiveBanner: (banner: Omit<Banner, 'id'>) => {
        dispatch(bannerActionCreators.upsertActiveBanner(banner));
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(UpdateBannerModal);