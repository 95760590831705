import { AdalConfig, AuthenticationContext } from "react-adal";

interface AskAliceAdalConfig extends AdalConfig {
    endpoints: {
        api: string
    }
}
export const getUserId = () => authContext.getCachedUser().userName;
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const adalConfig: AskAliceAdalConfig = {
    tenant: "152aa3c2-ede6-44a7-b407-977fd5dd3df4",
    clientId: "ba2f9395-6e43-4d3b-9e17-6969d3518023",
    redirectUri: window.location.origin + process.env.PUBLIC_URL,
    endpoints: {
        api: "ba2f9395-6e43-4d3b-9e17-6969d3518023"
    },
    cacheLocation: "sessionStorage"
};

export const authContext = new AuthenticationContext(adalConfig);
