import React, { FC, MouseEventHandler, PropsWithChildren, useRef, useState } from "react";
import Navigation from "./Navigation";
import Search from "./search/Search";
import Banner from "./banner/Banner";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";

const disabledAutocompleteRoutes = ["/help"];

interface LayoutProps extends PropsWithChildren {
}

const Layout: FC<LayoutProps> = (props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    
    const [isScrolledToTop, setIsScrolledToTop] = useState(true)
    const [searchValue, setSearchValue] = useState("");

    const location = useLocation();

    const handleHomeClicked = () => {
        setSearchValue("");
    }

    const handleScroll: MouseEventHandler<HTMLDivElement> = (event) => {
        setIsScrolledToTop(event.currentTarget.scrollTop === 0);
    }
    
    const isAutocompleteDisabled = disabledAutocompleteRoutes.includes(location.pathname)
    
    return (
        <div id="top" className="container-uhm" ref={containerRef}>
            <div className="container">
                <div id="aliceContentGrid" onScroll={handleScroll}>

                    <div>
                        <Navigation isScrolledToTop={isScrolledToTop}
                            homeClicked={handleHomeClicked} />
                    </div>
                    
                    <div className="content-flexbox">
                        <Banner />

                        {!isAutocompleteDisabled &&
                            <div className="col align-center search-bar">
                                <div className="autocomplete-order-row">
                                    <Search searchQuery={searchValue} setSearchQuery={setSearchValue} />
                                </div>
                            </div>
                        }

                        {props.children}

                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Layout;