import * as ActionTypes from "../common/actionTypes";

export const defaultFeedbackState: FeedbackState = {
    isLoading: false
}

// STATE
export interface FeedbackState {
    feedback?: Feedback;
    isLoading: boolean;
    error?: Error;
}

export interface Feedback {
    id?: number;
    answer: string;
    answerId?: number;
    confidenceScore?: number;
    alternateAnswer?: string; // TODO: Rename to alternate answer question
    alternateAnswerId?: number;
    helpfulFlag: boolean;
    feedbackTypeId?: number;
    question: string;
    userComments?: string;
    userId: string;
    navigationLink: string;
}

// ACTIONS
interface RequestCreateFeedback {
    type: typeof ActionTypes.RequestCreateFeedback;
    feedback: Omit<Feedback, "id">;
}

interface ReceiveCreateFeedback {
    type: typeof ActionTypes.ReceiveCreateFeedback;
    feedback: Feedback;
}

interface RequestUpdateFeedback {
    type: typeof ActionTypes.RequestUpdateFeedback;
    feedback: Feedback;
}

interface ReceiveUpdateFeedback {
    type: typeof ActionTypes.ReceiveUpdateFeedback;
}

interface HandleErrorFeedback {
    type: typeof ActionTypes.HandleErrorFeedback;
    error: Error;
}

interface ClearErrorFeedback {
    type: typeof ActionTypes.ClearErrorFeedback;
}

export type KnownAction =
    | RequestCreateFeedback
    | ReceiveCreateFeedback
    | RequestUpdateFeedback
    | ReceiveUpdateFeedback
    | HandleErrorFeedback
    | ClearErrorFeedback;
