import { FC, useEffect, useState } from "react";
import { bannerActionCreators } from "../../actions/bannerActions";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import UpdateBannerModal from "./UpdateBannerModal";
import * as FileHelper from "../../common/fileHelper";

interface BannerProps extends ConnectedProps<typeof connector> {
}

const fallbackBackgroundStyle = "url(images/Ask-Alice-Banner.svg)";

const Banner: FC<BannerProps> = (props) => {
    const [isUpdateBannerModalVisible, setIsUpdateBannerModalVisible] = useState(false);

    const { isLoading, banner } = props;
    const { readActiveBanner } = props;
    
    const isAdmin = useIsAdmin();

    useEffect(() => {
        if (!isLoading) {
            readActiveBanner();
        }
    }, []);

    const handleEditBannerButtonClick = () => {
        setIsUpdateBannerModalVisible(true);
    }
    
    const handleUpdateBannerModalClose = () => {
        setIsUpdateBannerModalVisible(false);
    }
    
    const getBannerImage = () => {
        let backgroundImageStyle = fallbackBackgroundStyle;
        
        if (banner?.content !== undefined && banner?.type !== undefined) {
            backgroundImageStyle = `url(${FileHelper.buildDataUrl(banner.content, banner.type)}), ${backgroundImageStyle}`;
        }
        
        return backgroundImageStyle;
    }
    
    const getBannerVideo = () => {
        let bannerVideo: string | undefined = undefined;
        
        if (banner?.content !== undefined && banner?.type !== undefined) {
            bannerVideo = FileHelper.buildDataUrl(banner.content, banner.type);
        }
        
        return bannerVideo;
    }
    
    return (
        <>
            <UpdateBannerModal isVisible={isUpdateBannerModalVisible} onClose={handleUpdateBannerModalClose} />
            
            <div className="row full align-center alice-hero">
                <div>
                    {banner === undefined ? (
                        <div className="alice-banner-img" style={{ backgroundImage: fallbackBackgroundStyle }} />
                    ) : (FileHelper.isImageFile(banner.type) ? (
                        <div className="alice-banner-img" style={{ backgroundImage: getBannerImage() }} />
                    ) : (FileHelper.isVideoFile(banner.type) ? (
                        <video autoPlay loop muted className="alice-banner-video">
                            <source src={getBannerVideo()} type={banner.type} />
                        </video>
                    ) : (
                        <div className="alice-banner-img" style={{ backgroundImage: fallbackBackgroundStyle }} />
                    )))}

                    {isAdmin ? <span className="edit-banner" onClick={handleEditBannerButtonClick}><i className="fa fa-camera" /></span> : <span></span>}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const isLoading = state.banner.isLoading;
    const banner = state.banner.data;

    return { isLoading, banner };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    readActiveBanner: () => {
        dispatch(bannerActionCreators.readActiveBanner());
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Banner);
