import { FC } from "react";

interface SearchSuggestionListEmptyProps {
}

const SearchSuggestionListEmpty: FC<SearchSuggestionListEmptyProps> = (props) => {
    return (
        <p className="message-holder">
            Alice can't find any suggested questions based on your search.
        </p>
    );
}

export default SearchSuggestionListEmpty;