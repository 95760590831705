import React from 'react';
import { FC } from "react";

interface AccessDeniedProps {
}

const AccessDenied: FC<AccessDeniedProps> = () => {
    return (
        <section className="hero access-denied">
            <div className="wrap">
                <h1>
                   Looks like you've fallen down the rabbit hole.
                </h1>
                <h3>
                    You don't have access to Ask Alice. Request access by emailing
                    <br />
                    <a href="mailto:UHUSupport@unionhomemortgage.com">UHUSupport@unionhomemortgage.com</a>.
                </h3>
            </div>
        </section>
    );
}

export default AccessDenied;